<template>
	<section class="bg-fondo d-flex a-center j-center" style="height:calc(100vh - 75px)">
		<div id="sign-in-button" />
		<div class="box d-flex flex-column">
			<p class="title text-center pt-5">
				Vincula tu <br /> nùmero de celular
			</p>
			<div class="row mx-0 j-center mt-4">
				<div class="col-9 mb-4">
					<small class="pl-3 text-general">
						Ingresa tu número de celular al cual quieres asociar a esta cuenta. Te enviaremos un código de
						seguridad.
					</small>
					<!-- <el-input v-model="nueva_pass" placeholder="Nueva contraseña" show-password class="w-100" /> -->
					<ValidationObserver ref="validacion">
						<div class="row mx-0 mt-2">
							<div class="col-12">
								<ValidationProvider v-slot="{ errors }" rules="required|numeric" name="Telefono">
									<el-input v-model="telefono" placeholder="Número de telefono" size="small"
										class="w-100 br-10 options-number">
										<div slot="prepend" class="d-middle">
											<img :src="bandera" class="br-2 obj-cover" width="35" height="20" />
											<el-select v-model="indicador" placeholder="+ 57" @change="seleccionarBandera">
												<el-option v-for="item in paises" :key="item.indicativo"
													:label="'+' + item.indicativo" :value="item.indicativo" />
											</el-select>
										</div>
									</el-input>
									<span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
								</ValidationProvider>
							</div>
						</div>
					</ValidationObserver>
				</div>
				<div class="col-9">
					<div class="btn-general f-18 br-8" style="height:44px;" @click="validarUsuario()">
						Guardar
					</div>
				</div>
			</div>
		</div>
		<!-- Partials -->
		<modal-error-pass ref="modalErrorPass" />
	</section>
</template>

<script>
import Registro from '~/services/registro';
import Service from '~/services/auth';
export default {
	components: {
		modalErrorPass: () => import('./partials/modalErrorPass')
	},
	data() {
		return {
			telefono: '',
			pais: null,
			bandera: '',
			paises: [],
			indicador: 57
		}
	},
	mounted() {
		this.getPaises();
	},
	methods: {
		errorPass() {
			this.$refs.modalErrorPass.toggle();
		},
		seleccionarBandera() {
			this.bandera = this.paises.find((item) => item.indicativo === this.indicador).bandera;
		},
		async validarUsuario() {
			try {
				const valid = await this.$refs.validacion.validate()
				if (!valid) return;

				const params = {
					telefono: this.telefono,
					correo: '',
				};
				const { data } = await Service.existeUsuario(params)
				if (data.existe) {
					return this.notificacion('Mensaje', 'Ya existe un usuario registrado con este número de teléfono', 'warning');
				}
				this.asignarTelefono();
			} catch (e) {
				this.errorCatch(e)
			}
		},
		async asignarTelefono() {
			try {
				const numeroCelular = `+${this.indicador}${this.telefono}`;
				const data = await Registro.enviarCodigoRegistro(numeroCelular);
				if (data) {
					this.$emit('save', { indicador: this.indicador, telefono: this.telefono })
					this.$router.push({ name: 'phone-verificar-codigo' });
				}
			} catch (error) {
				console.error(error);
			}
		},
		async getPaises() {
			try {
				const { data } = await Service.getPaises();
				this.paises = data.paises;
				this.bandera = data.paises.find((item) => parseInt(item.indicativo) === 57).bandera;
			} catch (e) {
				this.errorCatch(e)
			}
		},
	}
}
</script>
<style lang="scss" scoped>
.box {
	width: 597px;
	height: 55vh;
	border-radius: 12px;
	box-shadow: 0px 1px 4px #00000014;

	.title {
		font-size: 37px !important;
		font-weight: 600;
	}
}

@media (min-height: 300px) and (max-height: 780px) {
	.box {
		height: 69vh !important;
	}
}

@media (min-width: 300px) and (max-width: 695px) {
	.box {
		height: calc(100% - 1px) !important;
		width: 100%;
		border-radius: inherit !important;
	}
}
</style>